// import '../css/style.css';
// import '../css/theme.css';
// import '../css/custom.css';
// import '../css/bootstrap.min.css';
// import '../css/elegant-icons.min.css';
// import '../css/flexslider.min.css';

// import '../fonts/ElegantIcons.eot';
// import '../fonts/ElegantIcons.svg';
// import '../fonts/ElegantIcons.ttf';
// import '../fonts/ElegantIcons.woff';
// import '../fonts/et-line.eot';
// import '../fonts/et-line.svg';
// import '../fonts/et-line.ttf';
// import '../fonts/et-line.woff';
// import '../fonts/fa-solid-900.eot';
// import '../fonts/fa-solid-900.svg';
// import '../fonts/fa-solid-900.ttf';
// import '../fonts/fa-solid-900.woff';
// import '../fonts/glyphicons-halflings-regular.eot';
// import '../fonts/glyphicons-halflings-regular.svg';
// import '../fonts/glyphicons-halflings-regular.ttf';
// import '../fonts/glyphicons-halflings-regular.woff';

// import '../img/chicklet.png';

// require('../js/bootstrap.min.js');
// require('../js/jquery.flexslider-min.js');
// require('../js/jquery.min.js');
// require('../js/scripts.js');
// require('../js/skrollr.min.js');
// require('../js/isotope.min.js');
// require('../js/smooth-scroll.min.js');